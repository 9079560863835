// import classplusLogo from "../../../assets/Images/classplusLogo.png";
import classplusLogo from "../../assets/Images/classplusLogo.png";
import styles from "./Header.module.css";
import { useState, useEffect } from "react";
import { redirectWebinarPage } from "../../utils/redirectWebinarPage";
import trackEvent from "../utils";
// import trackEvent from "../utils"
const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showButtn, setSHowButton] = useState(
    scrollPosition > 1000 ? true : false
  );
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleButtonClick = () => {
    redirectWebinarPage(
      "https://api.whatsapp.com/send?phone=917303801545&text=I+want+a+free+Demo",
      "Livebuttonnew"
    );
    trackEvent("redirectWebinar","click","bookFreeDemoHeader");
  };
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    setSHowButton(position > 1000 ? true : false);
  };
  return (
    <nav className={styles.header}>
      <div className={styles.headerBox}>
        <img
          src={classplusLogo}
          alt="classplusLogo"
          className={styles.headerLogo}
          width="158px"
        />
        {true && (
          <button onClick={handleButtonClick}>Book a free demo</button>
        )}
      </div>
    </nav>
  );
};
export default Header;
